import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Page from "../components/page"
import { Link } from "gatsby"
import { colors } from "../theme"
import NotFound from "../img/not-found.svg"

const useStyles = makeStyles(theme => ({
  notFoundImage: {
    maxWidth: "80%",
    maxHeight: "80%",
  },
  labelContainer: {
    padding: 20,
  },
  label: {
    maxWidth: 400,
    fontSize: 16,
  },
  titleLabel: {
    fontSize: 48,
    fontWeight: "bold",
    color: colors.grayDarkest,
  },
  subTitleLabel: {
    color: colors.grayDark,
    marginTop: 16,
  },

  link: {
    fontSize: 16,
    textDecoration: "underline",
    color: colors.link,
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()
  const theme = useTheme()

  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <Page>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection={lg ? "row" : "column"}
        height="100vh"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <img
            className={classes.notFoundImage}
            src={NotFound}
            alt="not-found"
          />
        </Box>
        <Box
          className={classes.labelContainer}
          display="flex"
          flexGrow={1}
          flexDirection="column"
        >
          <span className={`${classes.titleLabel} ${classes.label}`}>
            Whoops! There’s nothing here.
          </span>
          <span className={`${classes.subTitleLabel} ${classes.label}`}>
            This url may be broken or this page may have been removed.&nbsp;
            <Link to="/">Click here</Link> to get back home.
          </span>
        </Box>
      </Box>
    </Page>
  )
}

export default NotFoundPage
